<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24" style="margin-bottom: 10px">
        <el-page-header @back="goBack" content="授权管理"></el-page-header>
      </el-col>
    </el-row> -->
    <div class="contant">
      <div class="btns">
        <el-button type="success" style="margin-bottom:10px;" @click="allIsMandate()"> 一键授权</el-button>
        </el-button>
        <el-button type="success" style="margin-bottom:10px;margin-right:10px" @click="toggleSelection()"> 批量授权</el-button>
        </el-button>
      </div>
      <div class="seitch">
        <el-tooltip :content="'Switch value: ' + value" placement="top">
          <el-switch @change="openMandate" v-model="value" active-text="开启授权管理" inactive-text="关闭授权管理" active-color="#13ce66" inactive-color="#ccc" active-value="0" inactive-value="1">
          </el-switch>
        </el-tooltip>
      </div>
    </div>

    <template>
      <el-table :data="userInfo" style="width: 100%" height="700">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="date" label="序号" type="index" width="120" align="center"> </el-table-column>

        <el-table-column prop="username" label="用户名" align="center"></el-table-column>
        <el-table-column prop="robotCode" label="机器码" align="center"></el-table-column>

        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div class="" style="margin-right:10px">
              <el-button type="primary" size="mini" icon="el-icon-edit" style="float:left" @click="updateIsMandate(scope.row)">授权</el-button>
              </el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="delMandate(scope.row)" style="float:right">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- 分页 -->
    <el-row>
      <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="getIsMandate"></el-pagination>
    </el-row>
  </div>
</template>
<script>

import usermandate from "@/api/manageApi/UserMandate";

export default {
  data() {
    return {
      value: '1',
      type: 0,
      multipleSelection: [], //  选中的数据

      current: 1,
      limit: 10,
      total: 0,

      userInfo: [],
      userMandateVo: {

        id: 0,
        username: "",
        robotCode: "",
        isMandate: "",
      },
      robotCode: "",
      username: "",
      password: "",
    };
  },

  created() {
    this.getAuthorize()
    this.getIsMandate()
  },

  methods: {
    openMandate() {
      usermandate.openMandate(this.value).then((res) => {
        return res.data.message
      })
    },
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    //查询是否开启授权管理
    getAuthorize(){
      usermandate.getAuthorize().then(res=>{
        if(res.data.message=="开启授权管理"){
          this.value = "0"
        }else if(res.data.message=="关闭授权管理"){
          this.value = "1"
        }
      })
    },
    // 未授权列表
    getIsMandate() {
      usermandate.getIsMandate(this.current, this.limit).then(res => {
        this.userInfo = res.data.data.list;
        this.total = res.data.data.total;
      })
    },
    // 管理员授权
    updateIsMandate(row) {
      this.userMandateVo.robotCode = row.robotCode
      this.userMandateVo.username = row.username

      usermandate.updateIsMandate(this.userMandateVo).then((resp) => {
        this.getIsMandate()
      })

    },

    // 管理员全部授权
    allIsMandate() {
      this.$confirm("是否将全部用户授权", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        //点击确定，指定then方法
        usermandate.allIsMandate().then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getIsMandate()
        });
      });
      // usermandate.allIsMandate().then((res) => {
      //   this.getIsMandate()
      // })
    },
    // 管理员删除
    // delMandate(row){
    //     usermandate.delMandate(row.id).then((res) => {
    //     })
    // },
    delMandate(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        usermandate.delMandate(row.id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getIsMandate()
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app{
  box-sizing: border-box;
  padding: 0 5px;
}
.box {
  display: flex;
}
.box:nth-child(1) {
  margin-bottom: 10px;
}
.box:nth-child(2) {
  margin-bottom: 10px;
}
.item {
  width: 70px;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
}
.item-inp {
  float: right;
}
.contant {
  margin: 10px 0 0 0;
  display: flex;

  .seitch {
    width: 280px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgb(248, 245, 245);
    border-radius: 5px;
    font-size: 18px;
  }
}
</style>